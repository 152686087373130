import { FuturesTradeModeEnum } from '@/constants/future/common'
import { FundingHistoryTabIdEnum, FundingHistoryTypeEnum } from '@/constants/future/funding-history'
import { TradeOrderTypesEnum } from '@/constants/trade/common'

/**
 * 获取现货交易页面路由地址
 */
export function getTradeRoutePath(id: string, type?: TradeOrderTypesEnum) {
  let url = `/trade/${id}?type=${type || TradeOrderTypesEnum.market}`
  return url
}
/**
 * 获取合约交易页面路由地址
 * @param id 币对 symbol
 * @param type
 * @param groupId 普通模式合约组 ID
 * @param takerGroupId 带单模式合约组 ID
 * @param copyTrading 是否带单交易
 */
export function getTradeFuturesRoutePath({
  id,
  type = TradeOrderTypesEnum.market,
  groupId,
  takerGroupId,
  copyTrading = false,
}: {
  id: string
  type?: string
  groupId?: string
  takerGroupId?: string
  copyTrading?: boolean
}) {
  let url = `/futures/${id}?type=${type}`

  if (groupId) {
    url += `&selectgroup=${groupId}`
  }

  if (takerGroupId) {
    url += `&takergroup=${takerGroupId}`
  }

  if (copyTrading) {
    url += `&mode=${FuturesTradeModeEnum.copyTrading}`
  } else {
    url += `&mode=${FuturesTradeModeEnum.common}`
  }
  return url
}

export function getTradeFuturesRoutePathWithGroupId() {
  return 'futures/BTCUSD'
}

type IGetFutureFundingRatePagePathParams = {
  type?: FundingHistoryTypeEnum
  tradeId?: any
  tab?: FundingHistoryTabIdEnum
}

/** 获取合约资金费率页面路径 */
export function getFutureFundingRatePagePath({
  type = FundingHistoryTypeEnum.fundingRate,
  tab = FundingHistoryTabIdEnum.usdt,
  tradeId,
}: IGetFutureFundingRatePagePathParams) {
  return `/futures/funding-history/${tab === FundingHistoryTabIdEnum.usdt ? '' : 'quarterly/'}${type}?tradeId=${
    tradeId || ''
  }`
}
